import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import show from "./assets/noun-show-6085877-FFFFFF.png";
import sparkle from "./assets/noun-sparkle-7207157-FFFFFF.png";
import mic from "./assets/noun-microphone-7208254-FFFFFF.png";
import apple from "./assets/Apple_Music_icon.svg";
import spotify from "./assets/Spotify_icon.svg.png";
import tiktok from "./assets/TikTok_Logo.webp";
import instagram from "./assets/Instagram_logo_2016.svg";
import tickets from "./assets/noun-tickets-6019361-FFFFFF.png";

const DURATION = 4000;

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [barClasses, setBarClasses] = useState({
    barScale1: "stop-animation",
    barScale2: "stop-animation",
    barScale3: "stop-animation",
  });

  const audioRef = useRef(null);

  useEffect(() => {
    let timer;
    if (isPlaying) {
      if (audioRef.current) {
        audioRef.current.play();
      }

      // Set interval to update the progress bar based on the current time
      timer = setInterval(() => {
        if (audioRef.current) {
          const elapsed = audioRef.current.currentTime; // Get elapsed time in seconds
          const totalDuration = audioRef.current.duration; // Get total duration in seconds

          if (elapsed >= totalDuration) {
            clearInterval(timer);
            setIsPlaying(false);
            setProgress(0);
            setBarClasses({
              barScale1: "stop-animation",
              barScale2: "stop-animation",
              barScale3: "stop-animation",
            });
            audioRef.current.pause();
            audioRef.current.currentTime = 0; // Reset the audio time to the beginning
          } else {
            // Calculate progress as a percentage
            const progressPercentage = (elapsed / totalDuration) * 100;
            setProgress(progressPercentage);
            setBarClasses({
              barScale1: "",
              barScale2: "",
              barScale3: "",
            });
          }
        }
      }, 100);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setBarClasses({
        barScale1: "stop-animation",
        barScale2: "stop-animation",
        barScale3: "stop-animation",
      });
    }

    return () => clearInterval(timer);
  }, [isPlaying]);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="main">
      <div className="left">
        <div className="nav-icon-set">
          <a
            href="https://www.facebook.com/events/howlin-hill/dancing-on-the-duck/854725326681945/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="nav-icon" src={show} alt="" />
          </a>{" "}
          <a
            href="https://www.eventbrite.com/e/dancing-on-the-duck-tickets-954946670197?aff=erelexpmlt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="nav-icon" src={tickets} alt="" />
          </a>{" "}
          <a
            href="https://www.youtube.com/@courtsieco"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="nav-icon" src={sparkle} alt="" />
          </a>
        </div>
      </div>
      <div className="center">
        <div className="audio-player">
          <div className="sound-wave">
            <svg preserveAspectRatio="none" viewBox="0 0 1440 560">
              <g mask='url("#SvgjsMask1099")' fill="none">
                <rect fill="#0e2a47"></rect>
                <g
                  transform="translate(0, 0)"
                  strokeLinecap="round"
                  stroke="url(#SvgjsLinearGradient1100)"
                >
                  {/* Add all bar paths here with dynamic classNames */}
                  <path
                    d="M375 202.15 L375 357.85"
                    strokeWidth="17.25"
                    className={`bar-scale2 ${barClasses.barScale2}`}
                  ></path>
                  <path
                    d="M398 155.33 L398 404.67"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M421 196.44 L421 363.56"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M444 259.91 L444 300.09"
                    strokeWidth="17.25"
                    className={`bar-scale1 ${barClasses.barScale1}`}
                  ></path>
                  <path
                    d="M467 208.25 L467 351.75"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M490 184.8 L490 375.2"
                    strokeWidth="17.25"
                    className={`bar-scale2 ${barClasses.barScale2}`}
                  ></path>
                  <path
                    d="M513 249.28 L513 310.72"
                    strokeWidth="17.25"
                    className={`bar-scale2 ${barClasses.barScale2}`}
                  ></path>
                  <path
                    d="M536 220.75 L536 339.25"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M559 254.8 L559 305.2"
                    strokeWidth="17.25"
                    className={`bar-scale1 ${barClasses.barScale1}`}
                  ></path>
                  <path
                    d="M582 186.77 L582 373.23"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M605 210.13 L605 349.87"
                    strokeWidth="17.25"
                    className={`bar-scale1 ${barClasses.barScale1}`}
                  ></path>
                  <path
                    d="M628 234.45 L628 325.55"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M651 241.1 L651 318.89"
                    strokeWidth="17.25"
                    className={`bar-scale2 ${barClasses.barScale2}`}
                  ></path>
                  <path
                    d="M674 202.95 L674 357.05"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M697 165.81 L697 394.19"
                    strokeWidth="17.25"
                    className={`bar-scale2 ${barClasses.barScale2}`}
                  ></path>
                  <path
                    d="M720 224.51 L720 335.49"
                    strokeWidth="17.25"
                    className={`bar-scale2 ${barClasses.barScale2}`}
                  ></path>
                  <path
                    d="M743 157.59 L743 402.4"
                    strokeWidth="17.25"
                    className={`bar-scale1 ${barClasses.barScale1}`}
                  ></path>
                  <path
                    d="M766 164.98 L766 395.02"
                    strokeWidth="17.25"
                    className={`bar-scale1 ${barClasses.barScale1}`}
                  ></path>
                  <path
                    d="M789 158.93 L789 401.07"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M812 224.24 L812 335.76"
                    strokeWidth="17.25"
                    className={`bar-scale2 ${barClasses.barScale2}`}
                  ></path>
                  <path
                    d="M835 171.73 L835 388.27"
                    strokeWidth="17.25"
                    className={`bar-scale1 ${barClasses.barScale1}`}
                  ></path>
                  <path
                    d="M858 264.89 L858 295.11"
                    strokeWidth="17.25"
                    className={`bar-scale2 ${barClasses.barScale2}`}
                  ></path>
                  <path
                    d="M881 175.14 L881 384.86"
                    strokeWidth="17.25"
                    className={`bar-scale1 ${barClasses.barScale1}`}
                  ></path>
                  <path
                    d="M904 248.17 L904 311.83"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M927 185.4 L927 374.6"
                    strokeWidth="17.25"
                    className={`bar-scale1 ${barClasses.barScale1}`}
                  ></path>
                  <path
                    d="M950 234.82 L950 325.18"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M973 229.9 L973 330.1"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M996 194.25 L996 365.75"
                    strokeWidth="17.25"
                    className={`bar-scale2 ${barClasses.barScale2}`}
                  ></path>
                  <path
                    d="M1019 162.47 L1019 397.53"
                    strokeWidth="17.25"
                    className={`bar-scale1 ${barClasses.barScale1}`}
                  ></path>
                  <path
                    d="M1042 205.06 L1042 354.94"
                    strokeWidth="17.25"
                    className={`bar-scale3 ${barClasses.barScale3}`}
                  ></path>
                  <path
                    d="M1065 240.52 L1065 319.48"
                    strokeWidth="17.25"
                    className={`bar-scale1 ${barClasses.barScale1}`}
                  ></path>
                </g>
              </g>
              <defs>
                <mask id="SvgjsMask1099">
                  <rect width="1440" height="560" fill="#ffffff"></rect>
                </mask>
                <linearGradient
                  x1="360"
                  y1="280"
                  x2="1080"
                  y2="280"
                  gradientUnits="userSpaceOnUse"
                  id="SvgjsLinearGradient1100"
                >
                  <stop stop-color="#3a7cc3" offset="0"></stop>
                  <stop stop-color="#dd1133" offset="1"></stop>
                </linearGradient>
              </defs>
            </svg>
            <div className="progress-bar">
              <div
                className="progress-color"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className="audio-buttons">
              <div onClick={togglePlayPause}>
                <audio
                  ref={audioRef}
                  src="Crazy(CoverJohn Wick Remix)-Gnarls BarkleyxCourtsie.mp3"
                />
                {isPlaying ? (
                  <svg
                    height="48"
                    viewBox="0 0 48 48"
                    width="48"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 38h8V10h-8v28zm16-28v28h8V10h-8z"
                      fill="#ffffff"
                    />
                    <path d="M0 0h48v48H0z" fill="none" />
                  </svg>
                ) : (
                  <svg
                    height="48"
                    viewBox="0 0 48 48"
                    width="48"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h48v48H0z" fill="none" />
                    <path
                      d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm-4 29V15l12 9-12 9z"
                      fill="#ffffff"
                    />
                  </svg>
                )}

                {/* <div
                className={`${isPlaying ? "play-button" : "pause-button"}`}
                onClick={togglePlayPause}
              > */}
                <audio
                  ref={audioRef}
                  src="Crazy(CoverJohn Wick Remix)-Gnarls BarkleyxCourtsie.mp3"
                />
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="social-icon-set">
          <a
            href="https://music.apple.com/us/artist/courtsie/1707186360"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="nav-icon" src={apple} alt="" />
          </a>
          <a
            href="https://www.tiktok.com/@courtneylyne"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="nav-icon tiktok" src={tiktok} alt="" />
          </a>
          <a
            href="https://www.instagram.com/courtneylynejohnson"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="nav-icon" src={instagram} alt="" />
          </a>
        </div>
      </div>
      <div className="right"></div>
    </div>
  );
}

export default App;
